/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
* {
  box-sizing: border-box;
  word-break: break-word;
}

.accordion-margin .accordion-item.custom-accordionItem {
  box-shadow: none !important;
}

#user-profile .profile-header.custom-profile-header {
  overflow: inherit;
}

@media only screen and (max-width: 767px) {
  .datepicker-sp {
    width: 100% !important;
  }
}

.custom-pretag {
  background-color: transparent !important;
  border-radius: 0;
  height: auto;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  /* line-height: 1.6 !important; */
  /* letter-spacing: 0.8px; */
  margin-bottom: 0 !important;
}

.custom-comment-textarea {
  min-height: 80px;
  height: 80px;
  width: 100%;
}

.custom-comment-textarea > div {
  width: 100%;
  height: 100%;
}

.custom-comment-textarea textarea.form-control {
  border: none !important;
  resize: none !important;
  width: 100%;
  height: 100%;
}

ul,
ol,
li {
  list-style: none;
}

/*Custom Group*/
.group-banner {
  background-image: linear-gradient(to left, #ffea00, #ff9800);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  min-height: 250px;
}

/* custom input search */
.mycustom_input_search,
.myinputSearch {
  background-color: #f4f5f4 !important;
  border: none !important;
}
.mycustom_input_search {
  border-radius: 0 8px 8px 0;
}
.myinputSearch {
  border-radius: 8px 0 0 8px;
}
.mycustom_input_search:focus,
.myinputSearch:focus {
  border: none;
}

.dropdown-menu.show.custom-menu-show {
  width: max-content;
}

.groupcard_img::after {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url('https://res.cloudinary.com/cloudhspace/image/upload/v1653925135/dev.hspace.biz/group/round_shape1_simvsk.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.custom-groupcard {
  @media only screen and (max-width: 991px) and (min-width: 767px) {
    flex-wrap: wrap;
    li {
      width: 100%;
    }
  }
  // @media only screen and (max-width: 767px){

  // }
}

.custom-inp-checkbox {
  cursor: pointer;

  input {
    position: absolute;
    transform: scale(0);
  }

  input:checked ~ .checkbox {
    transform: rotate(45deg);
    width: 12px;
    margin: -5px 7px 0 8px;
    border-color: #e6641f;
    border-width: 3px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
    box-shadow: 4px 2px 5px 0 rgba(230, 100, 31, 0.23);
  }

  .checkbox {
    display: block;
    width: 20px;
    height: 20px;
    border: 2px solid #999;
    border-radius: 4px;
    transition: all 0.2s cubic-bezier(0, 0.01, 0.23, 0.8);
  }
}

.member-thumb {
  margin-bottom: 40px;
  margin-left: -20px;
  padding-left: 20px;
  text-align: center;
}

.member-thumb li {
  display: inline-block;
  margin-right: -20px;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 50%;
}

.member-thumb li img {
  border-radius: 50%;
  height: 35px;
  width: 35px;
}
@media only screen and (max-width: 1199px) {
  .custom-create-group-sidebar {
    height: auto !important;
  }
  .custom-preview-group-nav {
    justify-content: space-between !important;
  }
  .custom-preview-group-main {
    max-width: inherit !important;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .custom-member-list-sp {
    .nav-item {
      margin-right: 0 !important;
      width: 100% !important;
      margin-bottom: 15px !important;
    }
  }

  .custom-create-group-layout-sp {
    padding: 0 0 !important;
  }
  .custom-modal-friend-sp {
    padding: 0 0 !important;
  }
  .custom-preview-group-nav {
    width: 100% !important;
  }
  .create-post-step-sp-list {
    margin-top: 10px !important;
    p,
    label {
      font-size: 1rem !important;
    }
  }
}

@media only screen and (max-width: 500px) {
  .custom-group-btn-sp {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  .custom-group-bg-sp {
    transform: translateY(-25%) !important;
    margin: 0 10px !important;
  }
}
@media only screen and (max-width: 450px) {
  .create-post-step-sp-list {
    margin-bottom: 15px;
  }
  .custom-preview-group-main {
    .container-fluid {
      padding: 0 0 !important;
    }
  }
}
.button-evodev {
  font-family: 'Poppins', sans-serif;
  color: white;
  padding: 15px 30px;
  width: 200px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  outline: none;
  border: none;
  font-size: 16px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.2s linear;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
}

.button-evodev:hover {
  transform: rotateY(-15deg);
}

/* TextArea */
.mycustom_textarea {
  border: none !important;
  background-color: #f4f5f4;
  resize: none;
  outline: none !important;
  border-radius: 10px !important;
}

.delete-img {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(31, 31, 31, 0.5);
  opacity: 0;
  transition: all 0.3s ease;
  border-radius: 8px;
  left: 0;
  top: 0;
}

.img_item:hover .delete-img {
  opacity: 1;
}

.delete-img * {
  transform: translateY(-70%);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.img_item:hover .delete-img {
  opacity: 1;
}

.img_item:hover .delete-img * {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (max-width: 550px) {
}

.custom-role-create {
  width: 100% !important;
  margin: 0 0 !important;
}
.nav-vertical
  .nav.nav-tabs.custom-role-create
  .nav-item
  .nav-link.active:after {
  content: none !important;
}
.nav-vertical
  .nav.nav-tabs.custom-role-create-2
  .nav-item
  .nav-link.active:after {
  right: inherit !important;
  left: 0;
}
.nav.nav-tabs.custom-role-create .nav-link.active {
  box-shadow: none !important;
  border-bottom: 2px solid #e6641f;
}
.nav.nav-tabs.custom-role-create .nav-link span {
  margin-left: 0 !important;
}
.custom-social-network-tab-left {
  max-height: 370px !important;
  overflow-y: scroll;
}

.custom-social-network-tab-left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-social-network-tab-left::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.custom-social-network-tab-left::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #999999;
}

.custom-card-create {
  max-width: 600px !important;
  margin: 0 auto;
}

.custom-text-area-role-create div {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
}
.custom-text-area-role-create textarea {
  resize: none !important;
  height: auto !important;
  margin-top: 5px;
  min-height: 130px;
}

@media only screen and (max-width: 767px) {
  .custom-text-area-role-create {
    margin-top: 20px;
  }
  .custom-card-create {
    max-width: inherit !important;
    margin: 0 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1320px) {
  .custom-role-table {
    min-width: 750px;
  }
}
@media only screen and (max-width: 991px) {
  .custom-role-table {
    min-width: inherit;
  }
}
@media only screen and (max-width: 767px) {
  .custom-role-table {
    min-width: 750px;
  }
}

//**FEED
@media only screen and (max-width: 767px) {
  .custom-container-card-md {
    max-width: 420px;
    padding: 0 20px;
    margin: 0 auto;
  }
}

.text_overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  &_2_line {
    -webkit-line-clamp: 2;
  }
  &_1_line {
    -webkit-line-clamp: 1;
  }
}
.custom-pull-up {
  background-color: #fff;
  img {
    box-shadow: none !important;
  }
}
.breaking-news-card {
  border-radius: 16px;
  border: 1px solid #e4e4e6;
  padding: 16px;
  display: flex;
  flex-direction: column;
}
.swiper-centered-slides.swiper-container .swiper-slide.swiper-slide-active {
  border: none !important;
}

.mySwiper {
  padding: 0 40px;

  @media only screen and (max-width: 767px) {
    padding: 0 20px;
  }
}

body.swal2-shown.swal2-height-auto {
  height: 100% !important;
}

.swiper-button-next {
  right: -10px !important;
}
.swiper-button-prev {
  left: -10px !important;
}

.kyc-title-list {
  li {
    position: relative;
    padding-left: 20px;
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.8)
        ),
        #038a96;
      border-radius: 50%;
    }
  }
}

.my-custom-form {
  .card-body {
    padding: 0 0 !important;
  }
  .card {
    margin-bottom: 0 !important;
  }
}
.my_custom_report_form {
  .card {
    margin-bottom: 0 !important;
  }
  .card-body {
    padding: 0 1rem !important;
  }
  textarea {
    resize: none !important;
    min-height: 190px;
  }
}

.custom-fz-hotnews > * {
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 0 !important;
  font-weight: 500;
}
.custom-fz-hotnews {
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 10px !important;
  min-height: 48px !important;
  font-weight: bold;
}

.newsfeed_fixed {
  position: sticky;
  top: 140px;
  height: calc(100vh - 160px);
  overflow-y: scroll;
  @media only screen and (max-width: 991px) {
    position: relative;
    top: inherit;
    height: auto;
    overflow-y: inherit;
  }
}
.newsfeed_fixed::-webkit-scrollbar {
  display: none;
}

.newsfeed_fixed {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.rdw-suggestion-dropdown {
  min-width: max-content !important;
  max-height: 200px !important;
  border-radius: 8px;
}

.rdw-suggestion-option {
  border: none !important;
  padding: 10px !important;
  cursor: pointer;
  transition: color 0.3s ease, background 0.3s ease, opacity 0.3s ease;
  border-radius: 8px;
}

.rdw-suggestion-option:hover {
  background-color: #fafafa;
}

.rdw-mention-link {
  color: #ff6700 !important;
  background-color: inherit !important;
}

.wysiwyg-mention {
  color: #ff6700 !important;
}
.rdw-editor-main {
  overflow: initial;
}

.rdw-suggestion-dropdown::-webkit-scrollbar {
  display: none;
}

.rdw-suggestion-dropdown {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.custom-hover {
  transition: color 0.3s ease, background 0.3s ease, opacity 0.3s ease;
  &:hover {
    opacity: 0.75;
  }
}
.custom-post.card .card-text p img {
  width: 100% !important;
}

.post-description p {
  background-color: inherit !important;
  margin-bottom: 0 !important;
}

.editor-class span,
.editor-class p {
  line-height: 1.2 !important;
}

.post-description span,
.post-description pre,
.post-description b,
.post-description i,
.post-description h1,
.post-description h2,
.post-description h3,
.post-description h4,
.post-description h5,
.post-description h6,
.post-description quotes,
.post-description code .post-description .post-description strong {
  background-color: inherit !important;
  font-size: 1rem !important;
  color: #6e6b7b !important;
  line-height: 1.2;
}
.post-description {
  a {
    span {
      color: #db5f1d !important;
      cursor: pointer;
    }
  }
}

.post-description img {
  object-fit: cover;
  width: 100% !important;
}

.post-description {
  height: auto;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}
.post-description.card-text img {
  width: 100% !important;
}
.request-card-left {
  width: 96px;
  height: 96px;
  margin-right: 16px;
  @media only screen and(max-width:450px) {
    width: 50px;
    height: 50px;
  }
}
.request-card-right {
  width: calc(100% - 112px);
  @media only screen and(max-width:450px) {
    width: calc(100% - 66px);
  }
}
.request-card-groupbtn {
  position: absolute;
  right: 16px;
  top: 16px;
}

@media only screen and (max-width: 1150px) and (min-width: 992px) {
  .request-card-groupbtn {
    position: relative;
    right: inherit;
    top: inherit;
    width: 100%;
    justify-content: end;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 660px) {
  .request-card-groupbtn {
    position: relative;
    right: inherit;
    top: inherit;
    width: 100%;
    justify-content: end;
    margin-top: 15px;
  }
}

.loading {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  .b {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .b div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #1C75BC;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .b div:nth-child(1) {
    left: 8px;
    animation: loading_b1 0.6s infinite;
  }

  .b div:nth-child(2) {
    left: 8px;
    animation: loading_b2 0.6s infinite;
  }

  .b div:nth-child(3) {
    left: 32px;
    animation: loading_b2 0.6s infinite;
  }

  .b div:nth-child(4) {
    left: 56px;
    animation: loading_b3 0.6s infinite;
  }
}

@keyframes loading_b1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes loading_b3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes loading_b2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

textarea {
  resize: none !important;
}

.custom-comment-editor {
  .public-DraftStyleDefault-block {
    margin: 0 0 !important;
  }
}
.custom-comment {
  .rdw-editor-wrapper {
    background-color: #f4f5f4 !important;
    border-radius: 10px !important;
    position: relative;
  }
  .rdw-editor-toolbar {
    padding: 0 0 !important;
    position: absolute;
    bottom: 0;
    right: 5px;
    border-radius: 10px;
    z-index: 500;
  }
  .rdw-option-wrapper {
    padding: 0 0 !important;
    margin: 0 0 !important;
    height: auto !important;
  }
  .rdw-editor-wrapper .rdw-editor-main {
    padding: 10px 80px 10px 10px !important;
  }
  .rdw-editor-main {
    border: none !important;
    overflow: inherit !important;
  }
  .public-DraftStyleDefault-block {
    margin: 0 0 !important;
  }
}
.abc .rdw-editor-main {
  overflow: initial !important;
}
.create-post-detail {
  .modal-content {
    padding-bottom: 150px !important;
    position: relative !important;
    overflow: initial !important;
  }
  .modal-body {
    overflow-y: scroll !important;
    position: relative !important;
    z-index: 300 !important;
  }
}
.custom-accordion-header {
  .accordion-button {
    padding: 0.5rem !important;
  }
}

.text-back40.custom-btn-active {
  background-color: #038a9633 !important;
  color: #011c1e !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
