.btn-light {
  &-AA {
    background: rgba(204, 204, 204, 0.12) !important;
    color: #cccccc !important;
  }

  &-FA {
    background: rgba(249, 184, 101, 0.12) !important;
    color: #f9b865 !important;
  }

  &-PUM {
    background: rgba(144, 190, 196, 0.12) !important;
    color: #90bec4 !important;
  }

  &-UM {
    background: rgba(71, 178, 137, 0.12) !important;
    color: #47b289 !important;
  }

  &-BM {
    background: rgba(252, 108, 31, 0.12) !important;
    color: #fc6c1f !important;
  }

  &-BDM {
    background: rgba(249, 184, 101, 0.12) !important;
    color: #e47883 !important;
  }

  &-Diamond {
    background: rgba(144, 223, 245, 0.12) !important;
    color: #90dff5 !important;
  }

  &-BlueDiamond {
    background: rgba(152, 215, 251, 0.12) !important;
    color: #98d7fb !important;
  }
}

.text {
  &-AA {
    color: #e9c891 !important;
  }

  &-FA {
    color: #8a8635 !important;
  }

  &-PUM {
    color: #ae431e !important;
  }

  &-UM {
    color: #d06224 !important;
  }

  &-BM {
    color: #bf0603 !important;
  }

  &-BDM {
    color: #8d0801 !important;
  }

  &-Diamond {
    color: #2155cd !important;
  }

  &-BlueDiamond {
    color: #010f7d !important;
  }

  &-grey80 {
    color: #cccccc !important;
  }

  /*Black*/
  &-black100 {
    color: #000000 !important;
  }

  &-primary-dark {
    color: #1c3218 !important;
  }

  &-back60 {
    color: #999999 !important;
  }

  &-back40 {
    color: #666666 !important;
  }

  &-black-20 {
    color: #333333 !important;
  }

  &-dark-1 {
    color: #011c1e !important;
  }

  /* White */
  &-white99 {
    color: #fcfcfc !important;
  }

  /* Green */
  &-alert {
    color: #8bcd41 !important;
  }

  /* Red */
  &-danger {
    color: #e04a56 !important;
  }

  &-black-20.btn-secondary {
    color: #333333 !important;
  }

  &-yellow-1 {
    color: #f78d2d !important;
  }
}

.gd {
  &-AA {
    background: linear-gradient(90deg, #cccccc 0%, #9eb0b6 100%) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &-FA {
    background: linear-gradient(90deg, #e8eb71 0%, #ffce4f 100%) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &-PUM {
    background: linear-gradient(90deg, #b6d0ba 0%, #78a2a8 100%) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &-UM {
    background: linear-gradient(90deg, #a2e293 0%, #47b289 100%) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &-BM {
    background: linear-gradient(90deg, #ffda91 0%, #fc6c1f 100%) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &-BDM {
    background: linear-gradient(90deg, #ff795c 0%, #c00000 100%) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &-Diamond {
    background: linear-gradient(90deg, #a3d6e1 0%, #29a1e4 100%) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &-BlueDiamond {
    background: linear-gradient(
      90deg,
      #9fdcfe 0%,
      #87c3d6 0.01%,
      #1278d2 100%
    ) !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      transform: translateY(-2px);
    }
  }
}

.fz {
  &-xxl {
    font-size: 2rem !important;
  }

  &-xl {
    font-size: 1.8rem !important;
  }

  &-lg {
    font-size: 1.6rem !important;
  }

  &-md {
    font-size: 1.4rem !important;
  }

  &-sm {
    font-size: 1.2rem !important;
  }

  &-xs {
    font-size: 1rem !important;
  }
}

@media only screen and (max-width: 900px) {
  .fz {
    &-xxl {
      font-size: 1.6rem !important;
    }

    &-xl {
      font-size: 1.4rem !important;
    }

    &-lg {
      font-size: 1.2rem !important;
    }

    &-md {
      font-size: 1rem !important;
    }

    &-sm {
      font-size: 1.2rem !important;
    }

    &-xs {
      font-size: .9rem !important;
    }
  }
}

/* SHADOW */
.card .card.custom_card_shadow {
  /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.06) !important; */
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%), 0 2px 2px rgba(0, 0, 0, 0.08);
  border: 1px solid #f4f5f4;
  padding-top: 20px;
}

.card {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 10%);
}

.custom-mb-10px {
  margin-bottom: 10px !important;
}
