@media only screen and (max-width: 1400px) {
  .menu-expanded {
    .request-card-groupbtn {
      position: relative;
      right: inherit;
      top: inherit;
      width: 100%;
      justify-content: end;
      margin-top: 15px;
    }
  }
}
