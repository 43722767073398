.form-control.form-date-picker {

  &:disabled,
  &[readonly] {
    background-color: #fff;
  }
}

@mixin threedot($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ck-editor__editable {
  min-height: 300px;
}

.three-dot-2 {
  @include threedot(2);
}

.three-dot-1 {
  @include threedot(1);
}

.modal-open {
  padding-right: 0 !important;
}

.react-select {
  .select__menu {
    z-index: 999;
  }
}

figure.image img {
  width: 100%;
}

svg {
  outline: none;
}

.custom-img-editer img {
  width: 100%;
}