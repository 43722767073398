.notifi-status-unread {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    /*position: absolute;*/
    border: 1px solid #fff;
}

.avatar-notifi-icon {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    right: -7px;
    bottom: -7px;
    position: absolute;
    border: 1px solid #fff;
    background-color: #038A96
}

.col-notifi {
    border: 2px solid #E4E4E6;
    border-radius: 10px;
}